const AUTHORIZATION = 'AUTHORIZATION';
// const USER_TOKEN = 'userToken';
// const USER_TOKEN_EXPIRATION = 'userTokenExpiration';
// const USER_NAME = 'userName';
const USER_ID = 'userId';
const USER_FULLNAME = 'userFullName';
const USER_STORE = 'userStore';
const USER_IDSTORE = 'userIdStore';
const USER_PROFILE = 'userProfile';
const USER_IDPROFILE = 'userIdProfile';
const USER_NOTIFICATIONS = 'userNotifications';

export const redirectToAdminPage = (page) => {
  if (localStorage.getItem('userProfile') === 'Operador') {
    window.location.href = '/app/sysprep/' + page;
  } else if (localStorage.getItem('userProfile') === 'Consultor') {
    window.location.href = '/app/consultor';
  } else {
    window.location.href = '/app/reports';
  }
};

export const redirectToLoginPage = () => {
  window.location.href = '/';
};

// função para autenticar o usuario
export const signIn = (auth) => {
  localStorage.setItem(AUTHORIZATION, JSON.stringify(auth));
};

// função para logout o usuario
export const signOut = () => {
  localStorage.removeItem(AUTHORIZATION);
  localStorage.removeItem(USER_NOTIFICATIONS);
};

export const getUserFullName = () => {
  return localStorage.getItem(USER_FULLNAME);
};

// função para retornar os dados do usuario autenticado
export const getAuthData = () => {
  return JSON.parse(localStorage.getItem(AUTHORIZATION));
};

export const setNotifications = (notifications) => {
  localStorage.setItem(USER_NOTIFICATIONS, JSON.stringify(notifications));
}

export const getNotifications = () => {  
  return JSON.parse(localStorage.getItem(USER_NOTIFICATIONS));
}

export const getUserId = () => {
  return localStorage.getItem(USER_ID);
};

export const getUserProfile = () => {
  return localStorage.getItem('userProfile');
};

// função para verificar se o usuario esta autenticado
export const isLoggedIn = () => {
  if (
    localStorage.getItem(AUTHORIZATION) !== null &&
    localStorage.getItem(AUTHORIZATION) !== undefined
  ) {
    const auth = getAuthData();

    return auth.accessToken && new Date(auth.userTokenExpiration) > new Date();
  }

  return false;
};

export const setUserTokenInfos = (user) => {
  window.localStorage.setItem(USER_ID, user.id);
  window.localStorage.setItem(USER_STORE, user.filial);
  window.localStorage.setItem(USER_FULLNAME, user.nome);
  window.localStorage.setItem(USER_PROFILE, user.perfil);
  window.localStorage.setItem(USER_IDPROFILE, user.idPerfil);
  window.localStorage.setItem(USER_IDSTORE, user.idFilial);
};
